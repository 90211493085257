import { LanguageSelect } from '@signatu/common-react'
import { getAPIEndpointConfig, isProd } from '@signatu/config-public'
import { CookieBannerPosition, CookieBannerVariant, DataProcessingGroupSelector } from '@signatu/consent-react'
import { DataProcessingGroup, isCookieBannerGroup, IsoLanguage, OAuthScope } from '@signatu/models'
import { OAuthClientApplicationActions, useSignatuSelector, useThunkDispatch } from '@signatu/models-redux'
import { AppLoaderProps } from '@signatu/sdk'
import { Actions as TrackerdetectActions } from '@signatu/trackerdetect-redux'
import { AppState } from '@signatu/trackerdetect-redux'
import React, { FC, useEffect, useState } from 'react'
import packageJSON from '../../../package.json'

export interface CookieConfigProps {
    type?: 'banner' | 'button'
    render(props: AppLoaderProps)
}

const CookieConfig: FC<CookieConfigProps> = (props) => {
    const { render, type = 'button' } = props
    const [group, setGroup] = useState<DataProcessingGroup | null>()
    const dispatch = useThunkDispatch()
    const [language, setLanguage] = useState<string>()

    const siteId = group?.metadata?.['signatu:siteId']
    const site = useSignatuSelector((state: AppState) => (siteId ? state.trackerdetectStore.sites[siteId] : null))
    const app = useSignatuSelector((state: AppState) =>
        site?.appId ? state.oauthclientapplicationStore.modelsById[site.appId] : null
    )
    /** Accept only scopes that have exactly 'consent' and 'dataprocessing:read' scopes */
    const token = app?.tokens
        ? app.tokens.filter(
              (t) =>
                  t.scopes.length === 3 && // DEFAULT, consent, dataprocessing:read
                  t.scopes.includes(OAuthScope.CONSENT) &&
                  t.scopes.includes(OAuthScope.DATAPROCESSING_READ)
          )[0]
        : null

    useEffect(() => {
        /** Find the SiteSpec from the group. The site has the `appId` we need */
        if (!siteId) {
            return
        }
        if (!site) {
            /** Load the site */
            dispatch(TrackerdetectActions.loadSite.started(siteId))
            return
        }
        /** We have a site, do we have an app? */
        if (site.appId && !app) {
            dispatch(OAuthClientApplicationActions.findById(site.appId))
            return
        }
    }, [site, siteId, dispatch, app])

    /** Configure endpoints for a non-production environment. We don't configure for production to keep the configuration of the tag small. */
    const endPoints = !isProd() ? getAPIEndpointConfig() : undefined

    return (
        <>
            <DataProcessingGroupSelector onChange={setGroup} filter={isCookieBannerGroup} />
            <LanguageSelect
                title="Language"
                emptyChoiceLabel="Autodetect"
                fullWidth={false}
                isoLanguage={language as IsoLanguage}
                onChange={(ev, language) => setLanguage(language)}
            />
            {group && !token && <div>Group is not a valid Cookie Banner group. Access keys are missing.</div>}
            {group &&
                token &&
                render({
                    origin: 'https://cdn.signatu.com',
                    version: packageJSON.version,
                    endPoints,
                    accessToken: token.id,
                    app: `cookie-${type}` as any,
                    language,
                    options: {
                        groupId: group.id,
                        position: CookieBannerPosition.CENTER,
                        variant: CookieBannerVariant.GDPR,
                    },
                })}
        </>
    )
}

export default CookieConfig
